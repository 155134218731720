import { Text } from '@chakra-ui/react'
import useStyle from 'hooks/style.'

const Error = ({ error }) => {
  const { errorColor } = useStyle()
  let errText = `${error}`
  try {
    errText = error.response.data.error
  } catch (err) { }

  return <Text mt={2} mb={2} color={errorColor}>{`${errText}`}</Text>
}

export default Error
